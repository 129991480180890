import "./App.css";
import { Route, Routes } from "react-router-dom";
import { publicRoutes } from "./routes/allRoutes";
import React from "react";
import NonLayout from "./layout/nonLayout";
function App() {
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<NonLayout>{route.component}</NonLayout>}
          />
        ))}
        {/* TODO : Update AuthProtected routes */}
      </Routes>
    </React.Fragment>
  );
}

export default App;
