import Background from "../../components/background/background";
import WithRouter from "../../components/common/withRouter";
import MainLogo from "../../components/mainLogo/mainLogo";
import NavHeader from "../../components/navHeader/navHeader";
import Footer from "../../components/footer/footer";
import styles from "./contact.module.css";

const Contact = () => {
  return (
    <div className={styles.contact}>
      <MainLogo />
      <NavHeader selected={"contact"} />
      <div className={styles.contactBody}>
        <h4>HEEHEE</h4>
        <div className={styles.contactInfoWrapper}>
          <div>Phone : 010-9741-3580</div>
          {/*Insta 링크 footer에 있으니 지우는 게 어떨까 */}
          <div>Instagram : youngnheehee</div>
          <div>Mail : yourheehee@gmail.com</div>
        </div>
      </div>
      <Footer heightPercentage={10} />
    </div>
  );
};

export default WithRouter(Contact);
