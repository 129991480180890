import styles from "./zoomModal.module.css";
import cancel from "../../images/cancel.png";
import { useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";

const useGesture = createUseGesture([dragAction, pinchAction]);

const ZoomModal = ({ children, setZoomEnable }) => {
  // prevent unexpected zooming
  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
  }));

  const zoomRef = useRef(null);

  const getDragBounds = () => {
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;
    return {
      left: -containerWidth * 0.7,
      right: containerWidth * 0.7,
      top: -containerHeight * 0.6,
      bottom: containerHeight * 0.6,
    };
  };

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y] }) => {
        if (pinching) return cancel();
        api.start({ x, y, immediate: true });
      },

      onPinch: ({ offset: [s] }) => {
        // api.start({ x, y, scale: s });
        api.start({ scale: s, immediate: true });
      },
    },
    {
      target: zoomRef,
      drag: {
        from: () => [style.x.get(), style.y.get()],
        // bounds: getDragBounds,
        delay: 0,
        threshold: 0,
      },
      pinch: {
        scaleBounds: { min: 1, max: 3.5 },
        rubberband: true,
      },
    }
  );
  return (
    <div className={styles.zoomModal} ref={zoomRef}>
      <div className={styles.zoomMenu}>
        <img
          src={cancel}
          alt="cancel"
          onClick={() => {
            setZoomEnable(false);
          }}
        />
      </div>
      <animated.div className={styles.zoomAnimated} style={style}>
        {children}
      </animated.div>
    </div>
  );
};

export default ZoomModal;
