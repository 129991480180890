import WithRouter from "../../components/common/withRouter";
import Footer from "../../components/footer/footer";
import MainLogo from "../../components/mainLogo/mainLogo";
import NavHeader from "../../components/navHeader/navHeader";
import { education, history, introduce } from "./about-archive";
import styles from "./about.module.css";
import HeeheeProfile from "../../images/heehee-profile.png";

const About = () => {
  return (
    <div className={styles.about}>
      <MainLogo />
      <NavHeader selected={"about"} />
      <div className={styles.aboutBody}>
        <h4 className={styles.aboutChapterTitle} style={{ marginTop: 0 }}>
          Note
        </h4>
        <div className={styles.contactProfileWrapper}>
          <img src={HeeheeProfile} alt=""></img>
        </div>
        <p className={styles.introduce}>{introduce.join(" ")}</p>
        <h4 className={styles.aboutChapterTitle}>Education</h4>
        <p>{education}</p>
        <h4 className={styles.aboutChapterTitle}>History</h4>
        <div className={styles.historyWrapper}>
          {history.map((item, index) => (
            <div className={styles.history} key={index}>
              <p className={styles.historyTitle}>{item.date}</p>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer heightPercentage={10} />
    </div>
  );
};

export default WithRouter(About);
