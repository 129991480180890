export const artDatas = [
  {
    file: "the-little-match-girl.png",
    title: "성냥팔이 소녀",
    info: "Printed on Diasec |	54 x 76.4 | 2023",
    description: [
      "희희 전래동화 시리즈",
      "",
      "가스의 온실효과가 상당하더니 빌트인 가스레인지가 규제 되면서 ",
      "인덕션만 남은 세상에 불맛을 그리워하는 사람들은 성냥을 사기 시작했다. ",
      "그렇게 성냥팔아 졸부 된 성냥팔이소녀 이야기",
    ],
  },
  {
    file: "snow-white.png",
    title: "백설공주",
    info: "Printed on Diasec |	54 x 76.4 | 2024",
    description: [
      "희희 전래동화 시리즈",
      "",
      "백설공주가 마녀의 독사과를 한입먹고 떨어트린 그 자리에서 독사과나무가 자란다.",
      "오염 된 토양과 방사능에 노출 된 세상에서 독사과는 기형으로 자라나 오히려 정상적인 사과가 된다.",
      "제대로 된 음식 하나 없고 기근이 가득한 세상에서 사과팔아 졸부가 된다.",
    ],
  },
  {
    file: "pinocchio.png",
    title: "피노키오",
    info: "Printed on Diasec |	54 x 76.4 | 2024",
    description: [
      "희희 전래동화 시리즈",
      "",
      "크리스마스에 트리가 빠지면 되겠는가?",
      "하지만 나무가 고갈 된 세상, 피노키오는 거짓말로 길어지는 코로 나무를 만들어내고",
      "나무사업으로 졸부가 된다.",
    ],
  },
  {
    file: "frankenstein.png",
    title: "프랑켄슈타인",
    info: "Printed on Diasec |	54 x 76.4 | 2024",
    description: [
      "희희 전래동화 시리즈",
      "",
      "빅터 박사가 죽은 시체를 짜집기하여 만들어 낸 생명체 프랑켄슈타인은",
      "빅터를 죽이고 전염병, 전쟁, 기근으로 인간이 사라져가는 인류역사 속에서",
      "가족을 살리고 싶어하는 사람들의 생명을 되살려내주고 있다.",
      "물론 그도 죽은 시체를 짜집기해서.",
    ],
  },
  {
    file: "the-last-supper.jpeg",
    title: "최후의 만찬",
    info: "Acrylic on canvas | 51 x 120 | 2022",
    description: [
      "명화테러 시리즈",
      "",
      "영국 네셔널갤러리에 있는 최후의 만찬 복사본에 환경운동가들이 접착제로 손을 붙이는 시위가 있었다.",
      `'나는 이 길을 걸어봤으니, 멸종되지 않으려면 그 무엇도 원망할 시간도 없다, 너희가 나서서 자연을 살려라'`,
      "이미 멸종한 공룡이 원작에서의 예수님 역할을 하며 멸종위기에 놓인 동물들에게 메세지를 전달하고 있다.",
      "동물들은 접착제로 자연에 필요한 요소들을 지구에 붙이고 있다.",
    ],
  },
  {
    file: "sunflower.png",
    title: "해바라기",
    info: "Acrylic on canvas | 50.3 x 76.3 | 2022",
    description: [
      "명화테러 시리즈",
      "",
      "환경운동가에 의해 토마토 스프 투척 테러가 있었던 빈센트 반 고흐의 해바라기.",
      "토마토 스프 캔을 버리지 않고 업사이클링 하였더니 시들시들하던 반 고흐의 해바라기는 만개했다.",
      "새활용은 자연을 살리는 일이 아닐까-",
    ],
  },
  {
    file: "massacre-in-korea.jpeg",
    title: "한국에서의 학살",
    info: "Acrylic on canvas | 61 x 91.5 | 2022",
    description: [
      "명화테러 시리즈",
      "",
      "전쟁+기근 이라는 메세지를 전달하고 싶었던 호주의 환경운동가들이",
      "6.25전쟁을 그린 파블로 피카소의 한국에서의 학살에 접착제로 손을 붙이는 시위가 있었다.",
      "원화에서의 병사들이 전쟁을 멈추고, 접착제로 무너진 집들을 성 같은 집으로 붙여주고 있다.",
      "전쟁을 멈추었더니 자연도 인간을 도와주고 있다.",
    ],
  },
  {
    file: "mona-lisa.jpeg",
    title: "모나리자",
    info: "Acrylic on canvas | 61 x 91.5 | 2022",
    description: [
      "명화테러 시리즈",
      "",
      "케이크 투척 사건이 있었던 모나리자.",
      "오히려 그 케이크를 맛있게 먹는 모나리자를 볼 수 있다.",
    ],
  },
  {
    file: "meules.png",
    title: "건초더미",
    info: "Printed on Diasec |	100 x 81 | 2022",
    description: [
      "명화테러 시리즈",
      "",
      "환경운동가의 감자스프 테러가 있었던 모네의 건초더미, 감자를 테러하는데에 사용하지 않고,",
      "땅에 비료로 사용한다. 감자 껍질은 토양을 건강하게 한다. ",
      "그렇게 비옥해진 토양에서 자라 난 감자들이 에너지가 넘쳐 춤을 추고 있다.",
      "또 그 옆에는 멸종이 된 줄 알았던 키위새가 아주 건강하게 되돌아왔다.",
    ],
  },
  {
    file: "ant-pigeon-safty-zone.png",
    title: "비둘기 개미 보호지역",
    info: "Acrylic on canvas | 60 x 60 | 2024",
    description: ["개미와 비둘기 보호구역 입니다 그들의 안전에 유의해주세요"],
  },
  {
    file: "pigeon-in-area.png",
    title: "비둘기 출몰 지역",
    info: "Acrylic on canvas | 60 x 60 | 2024",
    description: ["비둘기 출몰지역 입니다. 비둘기의 안전에 유의해주세요"],
  },
  {
    file: "ant-in-area.png",
    title: "개미 출몰지역",
    info: "Acrylic on canvas | 60 x 60 | 2024",
    description: ["개미 출몰지역 입니다. 개미의 안전에 유의해주세요"],
  },

  {
    file: "judith.jpg",
    title: "유디트",
    info: "Acrylic on canvas |	120 x 72 | 2023",
    description: [
      "자신의 고향을 침략당한 유디트의 미인계로 장수 홀로페르네스의 목을 벤 이야기",
      "화려한 빛이 난무하는 도시를 만든 인간에게 자신의 터전을 빼앗긴 비둘기는",
      "인간의 일상에 자연스럽게 파고들어 그들의 무해함을 완전히 신뢰하게 만든다.",
      "인간이 도시의 화려함에 취한 순간 비둘기는 목을 자른다.",
      "이내 그들은 자신들의 터전이었던 도시를 거머쥐게 된다.",
    ],
  },
  {
    file: "ferrari-ark.png",
    title: "페라리 방주",
    info: "Mix material | 페라리 자동차에 혼합재료 | 62 x 140 x 50 | 2023",
    description: [
      "세상이 종말하기 전, 마지막 방주로 ",
      "튼튼한 페라리를 획득한 희희는",
      "꼭 필요한 것만 넣었습니다.",
    ],
  },
  {
    file: "circle-of-life.png",
    title: "Circle of life",
    info: "Acrylic on canvas |	130 x 180 | 2024",
    description: [
      "회색에서 진주빛이 섞인 민트에서 보라, 다시 회색으로. 화룡정점으로 발은 핫핑크.",
      "비둘기는 대자연 그 자체라는 경이로움을 경험하는 순간",
      "생명의 순환의 아름다움을 느꼈다.",
    ],
  },
  {
    file: "extinct-bird.png",
    title: "EXTINCT BIRD",
    info: "Printed on Diasec | 80 x 110 | 2022",
    description: ["20XX년에 멸종 된 새"],
  },
  {
    file: "extinct-land-animal.png",
    title: "EXTINCT LAND ANIMAL",
    info: "Printed on Diasec | 80 x 110 | 2024",
    description: ["20XX년에 멸종 된 육상동물"],
  },
  {
    file: "extinct-sea-life.png",
    title: "EXTINCT SEA LIFE",
    info: "Printed on Diasec |	80 x 110 | 2024",
    description: ["20XX년에 멸종 된 해양생물"],
  },
  {
    file: "@bdoolgizip.png",
    title: "@bdoolgizip",
    info: "Printed on Diasec |	160 x 80 | 2024",
    description: [
      "2024년의 시대를 담은 작품,",
      "1팔로우 1그림이 유행하는 시대에",
      "당시 사람들은 무엇을 좋아하고 추구했는지 담겨져있다.",
    ],
  },
  {
    file: "napoleon.png",
    title: "나폴레용",
    info: "Printed on Diasec |	70.9 x 89.1 | 2024",
    description: [
      "작지만 세상을 정복한 나폴레옹처럼",
      "공룡을 지켜내기 위해 오늘도 치열하다",
    ],
  },
  {
    file: "baby-flamingo-wearing-salt-shoes.png",
    title: "소금 신발을 신은 아기 홍학",
    info: "Printed on Diasec |	130 x 50 | 2023",
    description: [
      "홍학은 사는 곳 상황이 나빠지면 다른 곳으로 이동했다가 조건이 다시 좋아지면 되돌아온다.",
      "이들은 이동 중 소금바다를 지나가게 되는데, 발을 빠르게 구르는 아기 홍학은 소금을 떨쳐내며 무리를 따라가고, ",
      "그렇지 못한 아기홍학은 발이 소금으로 뒤덮히기 시작하면서 그 자리에 굳은 채 낙오된다.",
      "이들은 어느곳이든 쓰레기로 뒤덮여있어 정착하지 못하는 바람에 12마리 밖에 남지 않았다.",
    ],
  },
  {
    file: "the-lion-king.png",
    title: "라이온킹",
    info: "Printed on Diasec |	82.1 x 102.3 | 2023",
    description: [
      "아기 사자 왕이 태어남을 경배할 동물이",
      "모두 멸종되어 한마리도 없다.",
      "왕족, 로열패밀리를 제외하고..",
    ],
  },

  {
    file: "the-scream.png",
    title: "절규",
    info: "Printed on Diasec |	81 x 103 | 2024",
    description: [
      "홍대로가려면 어디로 가야해요? 라고 길을 물었다.",
      "그리고 나도 모르게 뉴진스의 하잎보이요 라며 ",
      "춤을 추고 있는 나를 발견하며 HYPE, ",
      "허위, 과장과 유행에 미쳐버린 나를 발견하며 절규한다.",
    ],
  },
  {
    file: "the-kiss.png",
    title: "키스",
    info: "Printed on Diasec |	81 x 100 | 2024",
    description: ["클림트는 비둘기와 사랑에 빠져버렸다."],
  },
  {
    file: "thinking-man-statue.png",
    title: "생각만 하다 동상이 된 사람",
    info: "Printed on Diasec |	70 x 70 | 2024",
    description: [
      "sns 빠져 오늘만 사는 그는 변기위에서 그대로 동상이 되어버렸다.",
    ],
  },
  {
    file: "the-dance.png",
    title: "춤",
    info: "Printed on Diasec |	100 x 81 | 2024",
    description: [
      "벌목, 산불, 전쟁으로 나무가 고갈 된 세상에 씨앗을 심고",
      "나무가 잘 자라나기를 기원하는 의식을 치르고 있다.",
    ],
  },
  {
    file: "harmful-dog.png",
    title: "유해동물강아지",
    info: "Printed on Diasec |	70 x 70 | 2022",
    description: [
      "수도 없는 강아지 유기, 나라는 모든 견종을 유해동물로 지정함과 동시에 반려동물 금지령을 선포했다.",
    ],
  },
  {
    file: "big-tail-fox.png",
    title: "꼬리가 큰 여우",
    info: "Printed on Diasec |	40.6 x 40.6 | 2024",
    description: [
      "인간이 놓은 덫에서 겨우 탈출 한 여우는 후유증으로 꼬리가 부었다",
    ],
  },
  {
    file: "my-baby.png",
    title: "아가",
    info: "Printed on Diasec |	40.6 x 40.6 | 2024",
    description: [
      "사냥꾼의 총에 맞은 아기 투칸의 죽음을 받아들이지 못하고 수년째 품고 있는 엄마 투칸",
    ],
  },
  {
    file: "i-love-ring.png",
    title: "링을 좋아하는 코끼리",
    info: "Printed on Diasec |	40.6 x 40.6 | 2024",
    description: ["서커스단의 코끼리는 미치광이처럼 링을 좋아하게 되버렸다."],
  },
  {
    file: "dog-in-square.png",
    title: "멈뭄미",
    info: "Printed on Diasec |	70 x 70 | 2022",
    description: [
      "유해동물로 지정 된 강아지는 금지 반려동물이 되었고,",
      "이제는 영상으로만 볼 수 있는 강아지는 핸드폰의 네모 화면속에 갇혔다.",
    ],
  },
  {
    file: "takahe.png",
    title: "TAKAHE",
    info: "Printed on Diasec |	100 x 81 | 2022",
    description: [
      "멸종 된 타카헤는 고대 이집트가 남긴 벽화처럼 그림으로만 남아있다.",
    ],
  },
  {
    file: "red-jaguar.png",
    title: "빨간재규어",
    info: "Printed on Diasec |	25.4 x 50.8 | 2024",
    description: [
      "산불로 인해 자신이 피범벅이 된지도 모른 체, 새끼를 찾아다니는 본래 검정색인 재규어",
    ],
  },
  {
    file: "sunday-afternoon-on-the-island-of-la-grande-jatte.png",
    title: "그랑드자트 섬의 일요일 오후",
    info: "Printed on Diasec |	100 x 81 | 2024",
    description: [
      "사과의 종자가 단종이 되어 사과가 없는 세상,",
      "뉴턴은 나무에서 떨어지는 사과가 아니라 비둘기를 보고 중력의 존재를 눈치챘다는데",
    ],
  },
];
