import { useState } from "react";
import styles from "./Footer.module.css";
import insta from "./insta.png";
import insta_hover from "./insta_hover.png";

function Footer({ heightPercentage }) {
  const [isHover, setIsHover] = useState(false);
  var mouseOver = () => {
    setIsHover(true);
  };
  var mouseOut = () => {
    setIsHover(false);
  };
  return (
    <div
      className={styles.footer}
      // style={{ height: `${heightPercentage}%` }}
    >
      <a href="http://instagram.com/youngnheehee" target="_blank">
        <img
          src={isHover ? insta_hover : insta}
          onMouseOver={mouseOver}
          onMouseOut={mouseOut}
          onClick={mouseOut}
          alt="youngnheehee"
        ></img>
      </a>
    </div>
  );
}

export default Footer;
