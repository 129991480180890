import styles from "./Background.module.css";
function Background({ heightPercentage, backgroundSize }) {
  return (
    <div
      className={styles.bg}
      style={
        backgroundSize > 0
          ? {
              height: `${heightPercentage}%`,
              backgroundSize: `${backgroundSize}px`,
            }
          : { height: `${heightPercentage}%` }
      }
    ></div>
  );
}

export default Background;
