import { Link } from "react-router-dom";
import styles from "./title.module.css";
import heeheeLogo from "../../../images/heehee-logo.png";

function Title() {
  return (
    <div className={styles.header}>
      <img className={styles.headerImg} src={heeheeLogo} alt="HEEHEE" />{" "}
      <div className={styles.headerNav}>
        <Link to="/about">ABOUT</Link>
        <p>|</p>
        <Link to="/gallery">GALLERY</Link>
        <p>|</p>
        <Link to="/contact">CONTACT</Link>
      </div>
    </div>
  );
}

export default Title;
