import styles from "./image-card.module.css";
import leftArrow from "../../images/left-arrow.png";
import rightArrow from "../../images/right-arrow.png";
import { artDatas } from "./gallery-archive";
import { useEffect, useRef, useState } from "react";

const ImageCard = ({ currentIdx, setCurrentIdx }) => {
  const [translateX, setTranslateX] = useState(0);
  const [clientX, setClientX] = useState(0);
  const [fadeOutTrigger, setFadeOutTrigger] = useState(false);
  const [touches, setTouches] = useState(0);

  const imageCard = useRef(null);
  const imgWrapper = useRef(null);

  const onImgWrapperTouchStart = (e) => {
    setTouches(e.touches.length);
    setClientX(e.touches[0].clientX);
  };

  const onImgWrapperTouchMove = (e) => {
    if (touches > 1) {
      return;
    }
    imgWrapper.current.style.transition = `transform 0.3s ease`;
    imgWrapper.current.style.transform = `translateX(${
      translateX + e.touches[0].clientX - clientX
    }px)`;
  };

  const onImgWrapperTouchEnd = (e) => {
    if (!imgWrapper.current || !imageCard.current) {
      return;
    }

    const distance = Math.abs(clientX - e.changedTouches[0].clientX);

    if (distance < 70 || touches > 1) {
      imgWrapper.current.style.transform = `translateX(${translateX}px)`;
      return;
    }

    if (e.changedTouches[0].clientX > clientX) {
      if (currentIdx === 0) {
        imgWrapper.current.style.transform = `translateX(0px)`;
        return;
      }
    } else {
      if (currentIdx === artDatas.length - 1) {
        imgWrapper.current.style.transform = `translateX(-${
          imgWrapper.current.clientWidth - imageCard.current.clientWidth
        }px)`;
        return;
      }
    }

    setCurrentIdx(
      (prev) => prev + (e.changedTouches[0].clientX > clientX ? -1 : 1)
    );
    setFadeOutTrigger(true);

    setTimeout(() => {
      imgWrapper.current.style.transition = `none`;
    }, 300);

    setTimeout(() => {
      setFadeOutTrigger(false);
    }, 100);
  };

  useEffect(() => {
    setTranslateX(
      -currentIdx * imageCard.current.getBoundingClientRect().width // 소숫점까지 정확한 너비를 구하기 위해
    );
  }, [currentIdx]);

  useEffect(() => {
    if (imgWrapper.current)
      imgWrapper.current.style.transform = `translateX(${translateX}px)`;
  }, [translateX]);

  return (
    <div className={styles.imageCard}>
      <div className={styles.imageCardContents} ref={imageCard}>
        <div
          className={styles.imageCardImgWrapper}
          style={{ width: `${artDatas.length * 100}%` }}
          ref={imgWrapper}
        >
          {artDatas.map((v, idx) => {
            return (
              <div
                onTouchStart={onImgWrapperTouchStart}
                onTouchMove={onImgWrapperTouchMove}
                onTouchEnd={onImgWrapperTouchEnd}
                className={styles.imageCardImg}
                style={
                  idx === currentIdx ||
                  idx === currentIdx - 1 ||
                  idx === currentIdx + 1
                    ? {
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/detail/${v.file})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }
                    : null
                }
                key={idx}
              ></div>
            );
          })}
        </div>

        <div className={styles.imageCardTitle}>
          <h6>{artDatas[currentIdx].title}</h6>
          <p>{artDatas[currentIdx].info}</p>
        </div>

        <div className={styles.imageCardDescription}>
          {artDatas[currentIdx].description.map((v, idx) => {
            return <p key={idx}>{v !== "" ? v : <br />}</p>;
          })}
        </div>
      </div>

      {currentIdx > 0 ? (
        <div
          className={`${styles.imageCardLeftArrows} ${
            fadeOutTrigger ? styles.activateFadeOut : null
          }`}
          onClick={() => {
            setCurrentIdx(currentIdx - 1);
          }}
        >
          <img src={leftArrow} alt="<" />
        </div>
      ) : null}
      {currentIdx < artDatas.length - 1 ? (
        <div
          className={`${styles.imageCardRightArrows} ${
            fadeOutTrigger ? styles.activateFadeOut : null
          }`}
          onClick={() => {
            setCurrentIdx(currentIdx + 1);
          }}
        >
          <img src={rightArrow} alt=">" />
        </div>
      ) : null}
    </div>
  );
};

export default ImageCard;
