import { Link } from "react-router-dom";
import heeheeLogo from "../../images/heehee-logo.png";
import styles from "./mainLogo.module.css";
const MainLogo = () => {
  return (
    <Link className={styles.mainLogoImg} to="/">
      <img src={heeheeLogo} alt="HEEHEE" />
    </Link>
  );
};

export default MainLogo;
