import { Link } from "react-router-dom";
import styles from "./navHeader.module.css";
import { useEffect, useState } from "react";

const NavHeader = ({ selected }) => {
  const [selectedElement, setSelectedElement] = useState(null);

  useEffect(() => {
    setSelectedElement(document.getElementById(`nav_${selected}`));
  }, []);

  useEffect(() => {
    if (selectedElement) {
      selectedElement.style.color = "#4e00e9";
      selectedElement.style.fontWeight = 900;
    }
  }, [selectedElement]);

  const onMouseEnter = () => {
    if (selectedElement) {
      selectedElement.style = null;
    }
  };

  const onMouseLeave = () => {
    if (selectedElement) {
      selectedElement.style.color = "#4e00e9";
      selectedElement.style.fontWeight = 900;
    }
  };
  return (
    <div
      className={styles.navHeader}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link id="nav_home" to="/">
        HOME
      </Link>
      <Link id="nav_about" to="/about">
        ABOUT
      </Link>
      <Link id="nav_gallery" to="/gallery">
        GALLERY
      </Link>
      <Link id="nav_contact" to="/contact">
        CONTACT
      </Link>
    </div>
  );
};

export default NavHeader;
