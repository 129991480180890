import styles from "./modal.module.css";
import cancel from "../../images/cancel.png";
import zoom from "../../images/zoom.png";

const Modal = ({ children, setModalOpen, setZoomEnable }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modalBg} onClick={() => setModalOpen(false)}></div>
      <div className={styles.modalMenu}>
        <img src={zoom} alt="zoom" onClick={() => setZoomEnable(true)} />
        {setZoomEnable && (
          <img src={cancel} alt="cancel" onClick={() => setModalOpen(false)} />
        )}
      </div>
      <div className={styles.modalBody}>{children}</div>
    </div>
  );
};

export default Modal;
