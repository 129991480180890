import { useState } from "react";
import Top from "../../images/top-button.png";
import styles from "./topButton.module.css";

const TopButton = () => {
  const [scrollY, setScrollY] = useState(0);
  window.addEventListener("scroll", () => {
    setScrollY(window.scrollY);
  });
  return (
    <div className={styles.topButtonWrapper}>
      {scrollY > 120 ? (
        <button
          onClick={() => window.scrollTo(0, 0)}
          style={{
            width: `30px`,
            height: `30px`,
            border: `none`,
            backgroundColor: `transparent`,
            backgroundImage: `url(${Top})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></button>
      ) : null}
    </div>
  );
};

export default TopButton;
