import WithRouter from "../../components/common/withRouter";
import Title from "./title/title";
import styles from "./home.module.css";
import Background from "../../components/background/background";
import Footer from "../../components/footer/footer";

const Home = () => {
  return (
    <div className={styles.home}>
      <Background heightPercentage={85} />
      <Title />
      <Footer heightPercentage={10} />
    </div>
  );
};

export default WithRouter(Home);
