import Gallery from "../pages/gallery/gallery";
import Home from "../pages/home/home";
import Contact from "../pages/contact/contact";
import About from "../pages/about/about";

const publicRoutes = [
  { path: "/", component: <Home /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/gallery/:title", component: <Gallery /> },
  { path: "/contact", component: <Contact /> },
  { path: "/about", component: <About /> },
];

export { publicRoutes };
